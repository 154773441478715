import { type CreateMessage, type Message } from "ai";
import { AnimatePresence, motion } from "framer-motion";
import { SendHorizontal } from "lucide-react";
import React, { useRef, useState } from "react";

import { cn } from "~/lib/utils";

import { LoadingButton } from "../loading-button";
import { Textarea } from "../ui/textarea";

interface ChatBottombarProps {
  sendMessage: (newMessage: Message | CreateMessage) => void;
  isLoading: boolean;
}

export default function ChatBottombar({
  sendMessage,
  isLoading,
}: ChatBottombarProps) {
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    if (isLoading) {
      return;
    }
    if (message.trim()) {
      sendMessage({
        role: "user",
        content: message.trim(),
      });
      setMessage("");

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }

    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessage((prev) => prev + "\n");
    }
  };

  return (
    <div className="flex w-full items-center justify-between gap-2 p-2">
      <AnimatePresence initial={false}>
        <motion.div
          key="input"
          className="relative w-full"
          layout
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1 }}
          transition={{
            opacity: { duration: 0.05 },
            layout: {
              type: "spring",
              bounce: 0.15,
            },
          }}
        >
          <Textarea
            autoComplete="off"
            value={message}
            ref={inputRef}
            onKeyDown={handleKeyPress}
            onChange={handleInputChange}
            name="message"
            placeholder="Aa"
            className="bg-background flex h-9 w-full resize-none items-center overflow-hidden rounded-full border"
          ></Textarea>
        </motion.div>

        <LoadingButton
          variant="ghost"
          size="icon"
          isLoading={isLoading}
          hideChildrenWhenLoading
          className={cn(
            "h-9 w-9",
            "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted shrink-0 dark:hover:text-white",
            {
              "pointer-events-none cursor-none opacity-80": !message.trim(),
            },
          )}
          onClick={handleSend}
        >
          <SendHorizontal size={20} className="text-muted-foreground" />
        </LoadingButton>
      </AnimatePresence>
    </div>
  );
}
