import { Info } from "lucide-react";
import React from "react";

import { Avatar } from "../avatar";
import { type Bot } from "./chat";

interface ChatTopbarProps {
  bot: Bot;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
}

export const TopbarIcons = [{ icon: Info }];

export default function ChatTopbar({
  bot,
  subtitle,
  children,
}: ChatTopbarProps) {
  return (
    <div className="flex h-20 w-full items-center justify-between border-b p-4">
      <div className="flex items-center gap-2">
        <Avatar
          className="flex items-center justify-center"
          seed={bot.seed}
          initials="bot"
          avatarClassName="h-10 w-10"
        />
        <div className="flex flex-col">
          <span className="font-medium">{bot.name}</span>
          <span className="text-xs">{subtitle}</span>
        </div>
      </div>
      {children}
    </div>
  );
}
