"use client";

import { useTranslation } from "./provider";

interface TranslationProps {
  id: string;
  children?: string;
  replace?: Record<string, string | number>;
}

export function Translation({ id, children, replace }: TranslationProps) {
  const locale = getClientSideCookie("locale") ?? "sv";
  const linkTranslations =
    getClientSideCookie("@admin/show-translations") === "true";
  const translation = useTranslation(id, children, replace);
  if (linkTranslations) {
    const url = `https://cms.nextgenclassroom.se/admin/collections/localization?limit=10&page=1&search=${id}&locale=${locale}`;
    return (
      <span
        onClick={() => {
          window.open(url, "_blank");
        }}
        className="cursor-pointer border border-green-500 hover:bg-green-500"
      >
        {translation}
      </span>
    );
  }
  return <>{translation}</>;
}

interface Translation2Props {
  id: string;
  singular: string;
  plural: string;
  zero?: string;
  count: number;
  replace?: Record<string, string | number>;
}

export function Translation2({
  id,
  singular,
  plural,
  zero,
  count,
  replace,
}: Translation2Props) {
  const replaceWithCount = { ...(replace ?? {}), count };
  if (count === 0 && zero) {
    return (
      <Translation id={`${id}.zero`} replace={replaceWithCount}>
        {zero}
      </Translation>
    );
  }
  if (count === 1) {
    return (
      <Translation id={`${id}.singular`} replace={replaceWithCount}>
        {singular}
      </Translation>
    );
  }
  return (
    <Translation id={`${id}.plural`} replace={replaceWithCount}>
      {plural}
    </Translation>
  );
}

export const getClientSideCookie = (name: string): string | undefined => {
  if (typeof window === "undefined") {
    return undefined;
  }
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];

  return cookieValue;
};
